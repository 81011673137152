import axios from "axios";
import { BACKEND_URL } from "../utils/utils";

const getTenders = async () => {
  const response = await axios.get(`${BACKEND_URL}/api/tenders/actives`);
  return response;
};

const getTender = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}/api/tenders/${id}`, config);
  return response;
};

const postSubmit = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${BACKEND_URL}/api/histories/${id}`,
    data,
    config
  );
  return response;
};

const getSubmit = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/histories/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTenders,
  getTender,
  postSubmit,
  getSubmit,
};
