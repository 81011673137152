import React, { useState, useEffect } from "react";
import "../styles/footer.css";
import { HashLink } from "react-router-hash-link";
import projectService from "../services/project";
import { useLocation, Link } from "react-router-dom";
// import logo from "../asset/images/logo.png";

const year = new Date();

const Footer = () => {
  const location = useLocation();
  const [projects, setProjects] = useState(null);
  const [lang, setLang] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const langJSON = window.localStorage.getItem("Vesta_lang");
    if (langJSON) {
      setLang(langJSON);
    } else {
      setLang("en");
      window.localStorage.setItem("Vesta_lang", "en");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!lang) {
      return;
    }
    if (!projects) {
      fetchProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const fetchProjects = async () => {
    try {
      let response;
      if (lang === "en") {
        response = await projectService.getProjectsEn();
      } else {
        response = await projectService.getProjectsMm();
      }
      let projects = response.data.projects;
      setProjects(projects);
    } catch (error) {
      console.log(error);
    }
  };

  if (!projects) {
    return <div>Loading</div>;
  }

  return (
    <footer>
      <div className="footer-content">
        <h1>
          <span className="vesta">V</span>ESTA PROPERTY MANAGEMENT
        </h1>
        <div>
          <section className="footer-content-container">
            <div>
              <h3>CONTACT</h3>
              <div className="footer-content">
                <h5>Customer Service Hotline</h5>
                <a href="tel:+9514700169">(+95) 14700047</a> <br />
                <a href="tel:+9514700170">(+95) 14700048</a> <br />
                <span>(Office Hours: 07:30 to 21:30.)</span>
                <h5>Email Address</h5>
                <a href="mailto:enquiry@vesta.com.mm">enquiry@vesta.com.mm</a>
                <h5>Address</h5>
                <address>
                  No. 7-8, Kabar Aye Pagoda Road, Yankin Township, Yangon
                </address>
              </div>
            </div>
            <div>
              <h3>QUICK LINK</h3>
              <ul className="footer-content">
                <li>
                  <HashLink to={lang === "mm" ? "/mm/#home" : "/#home"} smooth>
                    Home
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={lang === "mm" ? "/mm/#about-us" : "/#about-us"}
                    smooth
                  >
                    About Us
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={lang === "mm" ? "/mm/#services" : "/#services"}
                    smooth
                  >
                    Services
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={lang === "mm" ? "/mm/#client" : "/#client"}
                    smooth
                  >
                    Our Clients
                  </HashLink>
                </li>
                {/* <li>
                  <HashLink to={lang === "mm" ? "/mm/#news" : "/#news"} smooth>
                    News & Updates
                  </HashLink>
                </li> */}
                <li>
                  <HashLink
                    to={lang === "mm" ? "/mm/#contact" : "/#contact"}
                    smooth
                  >
                    Contact
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={lang === "mm" ? "/mm/#location" : "/#location"}
                    smooth
                  >
                    Location
                  </HashLink>
                </li>
              </ul>
            </div>
            <div>
              <h3>PROJECTS</h3>
              <ul className="footer-content">
                {projects.map((project, index) => (
                  <li key={index}>
                    <Link to={`/project/${project.id}/${lang}`}>
                      {project.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          {/* <div className="footer-logo">
            <div className="img-container">
              <img src={logo} alt="vesta propery management" />
            </div>
          </div> */}
        </div>
      </div>
      <div className="footer-end">
        <p>Copyright&#64; {year.getFullYear()} by Vesta Property Management</p>
        {/* <p>copyright statement</p> */}
        <button onClick={() => setOpen(true)}>Disclaimer</button>
        <div className="mark">Developed By Axra Tech</div>
      </div>
      <div className={open ? "modal modal-open" : "modal modal-close"}>
        <h3>DISCLAIMER</h3>
        <p>
          All contents on this website is for general informational purposes
          only, will be subject to change at the sole discretion of Vesta
          Property Management ("Vesta"), and should not be taken to be any
          representation or warranty, whether express or implied. All
          intellectual property rights relating to the contents on this website
          are owned by and shall remain the exclusive property of Vesta.
        </p>
        <button onClick={() => setOpen(false)}>Close</button>
      </div>
    </footer>
  );
};

export default Footer;
