import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../styles/slide.css";
import { Pagination } from "swiper";
// import projectService from "../services/project";
import { Link } from "react-router-dom";

const ProjectsSlide = ({ lang, projects }) => {
  // const [projects, setProjects] = useState(null);
  // useEffect(() => {
  //   if (!projects) {
  //     fetchProjects();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const fetchProjects = async () => {
  //   try {
  //     let response;
  //     if (lang === "en") {
  //       response = await projectService.getProjectsEn();
  //     }
  //     if (lang === "mm") {
  //       response = await projectService.getProjectsMm();
  //     }
  //     let projects = response.data.projects;
  //     projects = await projects.map((project) => {
  //       let image_urls = project.pictures ? project.pictures.split("||") : [];
  //       return { ...project, pictures: image_urls };
  //     });
  //     setProjects(projects);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // if (!projects) {
  //   return <em>Loading...</em>;
  // }

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="ProjectsSwiper"
        breakpoints={{
          540: {
            slidesPerView: 3,
          },
          1550: {
            slidesPerView: 4,
          },
        }}
      >
        {projects.map((project, index) => (
          <SwiperSlide key={index}>
            <Link to={`/project/${project.id}/${lang}`}>
              <div className="img-container">
                <img className="project-img" src={project.pictures[0]} alt="" />
              </div>
              <h2>{project.title}</h2>
              <p>{project.topic}</p>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ProjectsSlide;
