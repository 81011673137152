class Token {
  #token = null;
  #id = null;

  constructor(token, id) {
    this.#token = token;
    this.#id = id;
  }

  get token() {
    return this.#token;
  }

  get id() {
    return this.#id;
  }
}

export let instanceToken = "";

export const initToken = (token, id) => {
  instanceToken = new Token(token, id);
};
