import React, { useState } from "react";
import Alert from "../components/Alert";
import "../styles/signup.css";
import userService from "../services/user";
import { useNavigate, Link } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [signedUp, setSignedUp] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    company_name: "",
    re_password: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    message: "",
    isError: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCreate = async () => {
    setErrors({});
    let err = {};
    if (!values.name) {
      err.name = "Name field is required";
    }
    if (!values.email) {
      err.email = "Email field is required";
    }
    if (!values.phone) {
      err.phone = "Phone field is required";
    }
    if (!values.company_name) {
      err.company_name = "Company Name field is required";
    }
    if (!values.password) {
      err.password = "Password field is required";
    }
    if (!values.re_password) {
      err.re_password = "Re Password field is required";
    }
    if (values.password !== values.re_password) {
      err.password = "Password and Re Password must be the same.";
      err.re_password = "Password and Re Password must be the same.";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      await userService.postUser({
        name: values.name,
        email: values.email,
        phone: values.phone,
        company_name: values.company_name,
        password: values.password,
      });
      setValues({
        name: "",
        email: "",
        password: "",
        phone: "",
        company_name: "",
        re_password: "",
      });
      setSignedUp(true);
      setShowAlert({
        message: "You have just signed up.",
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } catch (error) {
      console.log(error);
      if (error.response) {
        setShowAlert({ message: error.response.data.error, isError: true });
        setTimeout(() => {
          setShowAlert({ message: "", isError: false });
        }, 4000);
      } else {
        setShowAlert({ message: "Error on server", isError: true });
        setTimeout(() => {
          setShowAlert({ message: "", isError: false });
        }, 4000);
      }
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  return (
    <main id="sign-up">
      <div className="form-container">
        <article className="form-content">
          <h1>Sign Up</h1>
          <p>
            Please enter your detail to sign up and be part of our great
            community
          </p>
          <div>
            <p>Already have an account?</p>
            <button onClick={() => navigate("/signin")}>Sign In</button>
          </div>
        </article>
        <div className="form-body">
          {signedUp ? (
            <div className="message">
              <p>Your account has been created. </p>
              <p>
                <em>But need to wait approval from admin.</em>
              </p>
              <p>We will send an email when it is approved.</p>
              <p>
                <i>Pleas also check in spam.</i>
              </p>
              <button onClick={() => navigate("/")}>Go to home page</button>
            </div>
          ) : (
            <>
              <div className="form-control">
                <label htmlFor="name">Name</label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange("name")}
                />
                {errors.name && (
                  <span className="input-error-msg">{errors.name}</span>
                )}
              </div>
              <div className="form-control">
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange("email")}
                />
                {errors.email && (
                  <span className="input-error-msg">{errors.email}</span>
                )}
              </div>
              <div className="form-control">
                <label htmlFor="phone">Phone</label>
                <input
                  name="phone"
                  id="phone"
                  type="tel"
                  value={values.phone}
                  onChange={handleChange("phone")}
                />
                {errors.phone && (
                  <span className="input-error-msg">{errors.phone}</span>
                )}
              </div>
              <div className="form-control">
                <label htmlFor="company_name">Company Name</label>
                <input
                  name="company_name"
                  id="company_name"
                  type="text"
                  value={values.company_name}
                  onChange={handleChange("company_name")}
                />
                {errors.company_name && (
                  <span className="input-error-msg">{errors.company_name}</span>
                )}
              </div>
              <div className="form-control">
                <label htmlFor="password">Password</label>
                <input
                  name="password"
                  id="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange("password")}
                />
                {errors.password && (
                  <span className="input-error-msg">{errors.password}</span>
                )}
              </div>
              <div className="form-control">
                <label htmlFor="re_password">Re Password</label>
                <input
                  name="re_password"
                  id="re_password"
                  type="password"
                  value={values.re_password}
                  onChange={handleChange("re_password")}
                />
                {errors.re_password && (
                  <span className="input-error-msg">{errors.re_password}</span>
                )}
              </div>
              <div className="form-control">
                <button
                  onClick={handleCreate}
                  disabled={loading}
                  className={loading ? "loading-btn" : ""}
                >
                  SIGN UP
                </button>
              </div>
            </>
          )}
        </div>
        <div className="go-home">
          <Link to="/">&#8592;</Link>
        </div>
      </div>
      {showAlert.message && (
        <Alert message={showAlert.message} isError={showAlert.isError} />
      )}
    </main>
  );
};

export default SignUp;
