import axios from "axios";
import { BACKEND_URL } from "../utils/utils";
// const BACKEND_URL = "http://localhost:3001";
// const BACKEND_URL = "http://157.230.242.167:3001";

const getServicesEn = async () => {
  const response = await axios.get(`${BACKEND_URL}/api/services/en`);
  return response;
};

const getServicesMm = async () => {
  const response = await axios.get(`${BACKEND_URL}/api/services/mm`);
  return response;
};

// const getService = async (token, id) => {
//   const config = {
//     headers: { authorization: `Bearer ${token}` },
//   };
//   const response = await axios.get(`${BACKEND_URL}/api/services/${id}`, config);
//   return response;
// };

const getServiceEn = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/services/${id}/en`);
  return response;
};

const getServiceMm = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/services/${id}/mm`);
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getServicesEn,
  getServicesMm,
  getServiceEn,
  getServiceMm,
};
