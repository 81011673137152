import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/service.css";
import serviceService from "../services/service";
import { useParams } from "react-router-dom";
import ServiceSlide from "../components/ServiceSlide";
import moment from "moment";
import { HashLink } from "react-router-hash-link";

const Service = () => {
  const { id, lang } = useParams();
  const [service, setService] = useState(null);

  useEffect(() => {
    if (!service) {
      fetchService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchService = async () => {
    try {
      let response;
      if (lang === "en") {
        response = await serviceService.getServiceEn(id);
      }
      if (lang === "mm") {
        response = await serviceService.getServiceMm(id);
      }
      const service = response.data;
      let image_urls = service.pictures ? service.pictures.split("||") : [];
      setService({ ...service, pictures: image_urls });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="service">
      <Header />
      <main>
        {!service ? (
          <em>Loading...</em>
        ) : (
          <>
            <div className="service-img-container">
              <ServiceSlide images={service.pictures} />
            </div>
            <h1>{service.title}</h1>
            <div>
              <p
                className="service-content"
                dangerouslySetInnerHTML={{ __html: service.description }}
              ></p>
            </div>
            <p className="service-info-content">
              <em>Created On :</em>
              <span>
                {moment(service.created_at).format("dddd, MMMM Do YYYY")}
              </span>
            </p>
            <div className="go-back">
              <HashLink
                to={lang === "mm" ? "/mm/#services" : "/#services"}
                smooth
              >
                &#8592;
              </HashLink>
            </div>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Service;
