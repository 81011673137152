import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/project.css";
import projectService from "../services/project";
import { useParams, useLocation } from "react-router-dom";
import ProjectSlide from "../components/ProjectSlide";
import { HashLink } from "react-router-hash-link";

const Project = () => {
  const location = useLocation();
  const { id, lang } = useParams();
  const [project, setproject] = useState(null);

  useEffect(() => {
    fetchproject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const fetchproject = async () => {
    try {
      let response;
      if (lang === "en") {
        response = await projectService.getProjectEn(id);
      }
      if (lang === "mm") {
        response = await projectService.getProjectMm(id);
      }
      const project = response.data;
      let image_urls = project.pictures ? project.pictures.split("||") : [];
      setproject({ ...project, pictures: image_urls });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="project">
      <Header />
      <main>
        {!project ? (
          <em>Loading...</em>
        ) : (
          <>
            <div className="project-img-container">
              <ProjectSlide images={project.pictures} />
            </div>
            <h1>{project.title}</h1>
            <div>
              <p
                className="project-content"
                dangerouslySetInnerHTML={{ __html: project.description }}
              ></p>
            </div>
          </>
        )}
        <div className="go-back">
          <HashLink to={lang === "mm" ? "/mm/#client" : "/#client"} smooth>
            &#8592;
          </HashLink>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Project;
