import React, { useState } from "react";
import "../styles/signup.css";
import { useNavigate } from "react-router-dom";
import userService from "../services/user";
import Alert from "../components/Alert";

const Forgot = () => {
  const navigate = useNavigate();
  const [next, setNext] = useState(0);
  const [values, setValues] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    message: "",
  });
  const [nextValues, setNextValues] = useState({
    otp: "",
    password: "",
    re_password: "",
  });
  const [nextErrors, setNextErrors] = useState({});

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleNextChange = (prop) => (event) => {
    setNextValues({ ...nextValues, [prop]: event.target.value });
  };

  const handleNext = async () => {
    setErrors({});
    let err = {};
    if (!values.email) {
      err.email = "Email field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      const response = await userService.postOTP({
        email: values.email,
      });
      console.log(response);
      setNext(response.data);
      setValues({ email: "" });
      setShowAlert({
        message: "OTP code have been sent. Please check your email",
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } catch (error) {
      console.log(error);
      if (error.response) {
        setShowAlert({ message: error.response.data.error, isError: true });
        setTimeout(() => {
          setShowAlert({ message: "", isError: false });
        }, 4000);
      } else {
        setShowAlert({ message: "Error on server", isError: true });
        setTimeout(() => {
          setShowAlert({ message: "", isError: false });
        }, 4000);
      }
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  const handleConfirm = async () => {
    setErrors({});
    let err = {};
    if (!nextValues.otp) {
      err.name = "Name field is required";
    }
    if (!nextValues.password) {
      err.password = "Password field is required";
    }
    if (!nextValues.re_password) {
      err.re_password = "Re Password field is required";
    }
    if (nextValues.password !== nextValues.re_password) {
      err.password = "Password and Re Password must be the same.";
      err.re_password = "Password and Re Password must be the same.";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setNextErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      await userService.putChangePassword(
        {
          otp: nextValues.otp,
          password: nextValues.password,
        },
        next
      );
      setNextValues({
        otp: "",
        password: "",
        re_password: "",
      });
      setShowAlert({
        message: "Password have been changed",
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } catch (error) {
      console.log(error);
      if (error.response) {
        setShowAlert({ message: error.response.data.error, isError: true });
      } else {
        setShowAlert({ message: "Error on server", isError: true });
      }
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      setLoading(false);
      setNextErrors({});
    }
  };

  return (
    <main id="forgot">
      {!next ? (
        <div className="form-container">
          <article className="form-content">
            <h1>Get OTP</h1>
            <p>Please enter your email and move on.</p>
            <div>
              <p>Create an account?</p>
              <button onClick={() => navigate("/signup")}>Sign Up</button>
            </div>
          </article>
          <div className="form-body">
            <div className="form-control">
              <label htmlFor="email">Email</label>
              <input
                name="email"
                id="email"
                type="email"
                value={values.email}
                onChange={handleChange("email")}
              />
              {errors.email && (
                <span className="input-error-msg">{errors.email}</span>
              )}
            </div>
            <div className="form-control">
              <button
                onClick={handleNext}
                disabled={loading}
                className={loading ? "loading-btn" : ""}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="form-container">
          <article className="form-content">
            <h1>Change Password</h1>
            <p>Check your email and get OTP</p>
            <div>
              <p>Already have an account?</p>
              <button onClick={() => navigate("/signin")}>Sign In</button>
            </div>
          </article>
          <div className="form-body">
            <div className="form-control">
              <label htmlFor="otp">OTP</label>
              <input
                name="otp"
                id="otp"
                type="text"
                value={nextValues.otp}
                onChange={handleNextChange("otp")}
              />
              {nextErrors.otp && (
                <span className="input-error-msg">{nextErrors.otp}</span>
              )}
            </div>
            <div className="form-control">
              <label htmlFor="password">Password</label>
              <input
                name="password"
                id="password"
                type="password"
                value={nextValues.password}
                onChange={handleNextChange("password")}
              />
              {nextErrors.password && (
                <span className="input-error-msg">{nextErrors.password}</span>
              )}
            </div>
            <div className="form-control">
              <label htmlFor="re_password">Re Password</label>
              <input
                name="re_password"
                id="re_password"
                type="password"
                value={nextValues.re_password}
                onChange={handleNextChange("re_password")}
              />
              {nextErrors.re_password && (
                <span className="input-error-msg">
                  {nextErrors.re_password}
                </span>
              )}
            </div>
            <div className="form-control">
              <button
                onClick={handleConfirm}
                disabled={loading}
                className={loading ? "loading-btn" : ""}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {showAlert.message && (
        <Alert message={showAlert.message} isError={showAlert.isError} />
      )}
    </main>
  );
};

export default Forgot;
