import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "../styles/slide.css";
import { Pagination, EffectFade, Autoplay } from "swiper";
import bannerService from "../services/banner";

// import bannerImg from "../asset/images/banner.png";

const BannerSlide = () => {
  const [banners, setBanners] = useState(null);
  useEffect(() => {
    if (!banners) {
      fetchBanners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBanners = async () => {
    try {
      const response = await bannerService.getBanners();
      let banners = response.data;
      setBanners(banners);
    } catch (error) {
      console.log(error);
    }
  };

  if (!banners) {
    return <em>Loading...</em>;
  }

  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Pagination, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="BannerSwiper"
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <img src={banner.picture} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default BannerSlide;
