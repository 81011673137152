import axios from "axios";
import { BACKEND_URL } from "../utils/utils";
// const BACKEND_URL = "http://localhost:3001";
// const BACKEND_URL = "http://157.230.242.167:3001";

const postContact = async (data) => {
  const response = await axios.post(`${BACKEND_URL}/api/contacts`, data);
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postContact,
};
