import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/tenders.css";
import { useNavigate } from "react-router-dom";
import tenderService from "../services/tender";

const Tenders = () => {
  const navigate = useNavigate();
  const [tenders, setTenders] = useState(null);

  useEffect(() => {
    if (!tenders) {
      fetchTendres();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTendres = async () => {
    try {
      const response = await tenderService.getTenders();
      let tenders = response.data.tenders;
      tenders = await tenders.map((tender) => {
        let image_urls = tender.pictures ? tender.pictures.split("||") : [];
        return { ...tender, pictures: image_urls };
      });
      setTenders(tenders);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="tenders">
      <Header />
      <main>
        <h1>E-TENDER FORMS</h1>
        {!tenders ? (
          <div>
            <em>Loading...</em>
          </div>
        ) : (
          <section>
            {tenders.length === 0 && <p>There is no tender.</p>}
            {tenders.length > 0 &&
              tenders.map((row, index) => (
                <article className="tenders-container" key={index}>
                  <div className="tenders-img-container">
                    <img src={row.pictures[0]} alt="" />
                  </div>
                  <div className="tenders-body">
                    <div className="tenders-header">
                      <h3>{row.name}</h3>
                      {new Date(row.end_date).getTime() <
                        new Date().getTime() && <span>Closed</span>}
                    </div>
                    <p>Category : {row.category}</p>
                    <p>{row.topic}</p>

                    <button
                      className="tender-detail-btn"
                      onClick={() => navigate(`/auth/tender/${row.id}`)}
                    >
                      More Detail
                    </button>
                  </div>
                </article>
              ))}
          </section>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Tenders;
