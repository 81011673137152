import React, { useEffect, useState } from "react";
import "../styles/home.css";
import aboutImg from "../asset/images/about_bg.png";
import mapImg from "../asset/images/map.jpg";
import mapImg2 from "../asset/images/map2.jpg";
// import projectImg from "../asset/images/project.png";
import NewsSlide from "../components/NewsSlide";
import Footer from "../components/Footer";
import Alert from "../components/Alert";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Header from "../components/Header";
import ProjectsSlide from "../components/ProjectsSlide";
import serviceService from "../services/service";
import { Link } from "react-router-dom";
import contactService from "../services/contact";
import newsService from "../services/news";
import projectService from "../services/project";

const Home = () => {
  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
  const [services, setServices] = useState(null);
  const [news, setNews] = useState(null);
  const [projects, setProjects] = useState(null);

  const [values, setValues] = useState({
    interestedService: "",
    name: "",
    companyName: "",
    phone: "",
    email: "",
    countryCode: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    message: "",
    isError: false,
  });

  useEffect(() => {
    if (!services) {
      fetchServices();
    }
    if (!news) {
      fetchNews();
    }
    if (!projects) {
      fetchProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNews = async () => {
    try {
      const response = await newsService.getNews();
      let news = response.data.news;
      setNews(news);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjects = async () => {
    try {
      let response;
      response = await projectService.getProjectsEn();
      let projects = response.data.projects;
      projects = await projects.map((project) => {
        let image_urls = project.pictures ? project.pictures.split("||") : [];
        return { ...project, pictures: image_urls };
      });
      setProjects(projects);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setErrors({});
    let err = {};
    if (!values.interestedService) {
      err.interestedService = "Interested Service is required";
    }
    if (!values.name) {
      err.name = "Name is required";
    }
    if (!values.companyName) {
      err.companyName = "Company Name is required";
    }
    if (!values.countryCode || !values.phone) {
      err.phone = "Phone Number is required";
    }
    if (isNaN(values.phone || isNaN(values.countryCode))) {
      err.phone = "Phone Number must be numbers.";
    }
    if (!values.email) {
      err.email = "Email is required";
    }
    if (
      values.email &&
      // eslint-disable-next-line no-useless-escape
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
    ) {
      err.email = "Enter correct email";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      await contactService.postContact({
        name: values.name,
        email: values.email,
        phone: "+" + values.countryCode + values.phone,
        companyName: values.companyName,
        interestedService: values.interestedService,
      });
      setValues({
        interestedService: "",
        name: "",
        companyName: "",
        phone: "",
        email: "",
        countryCode: "",
      });
      setShowAlert({
        message: "You have just sent your contact. Thank you!",
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } catch (error) {
      console.log(error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  const fetchServices = async () => {
    try {
      const response = await serviceService.getServicesEn();
      let services = response.data.services;
      services = await services.map((service) => {
        let image_urls = service.pictures ? service.pictures.split("||") : [];
        return { ...service, pictures: image_urls };
      });
      setServices(services);
    } catch (error) {
      console.log(error);
    }
  };

  // let getRatio = (el) =>
  //   window.innerHeight / (window.innerHeight + el.offsetHeight);

  // useEffect(() => {
  // gsap.fromTo(
  //   ".bg",
  //   {
  //     backgroundPosition: () =>
  //       `50% ${-window.innerHeight * getRatio(serviceRef.current) * 0.4}px`,
  //   },
  //   {
  //     backgroundPosition: () =>
  //       `50% ${window.innerHeight * (0.3 * getRatio(serviceRef.current))}px`,
  //     ease: "linear",
  //     scrollTrigger: {
  //       trigger: "#services",
  //       start: "top bottom",
  //       end: "bottom top",
  //       scrub: true,
  //       invalidateOnRefresh: true, // to make it responsive
  //     },
  //   }
  // );
  // gsap.fromTo(
  //   ".project-img",
  //   {
  //     scale: 0.5,
  //   },
  //   {
  //     scale: 1,
  //     duration: 1.5,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: "#client",
  //       start: "top 75%",
  //       end: "bottom 20%",
  //       // markers: true,
  //       toggleActions: "restart complete restart complete",
  //       invalidateOnRefresh: true, // to make it responsive
  //     },
  //   }
  // );
  // }, []);

  if (!services || !news || !projects) {
    return <div>Loading</div>;
  }

  return (
    <>
      <Header />
      <main>
        <article id="home" className="content-container welcome-container">
          <div className="content-wrapper">
            <h3 id="test">
              <span className="vesta">V</span>ESTA PROPERTY MANAGEMENT
            </h3>
            <h2>WELCOME</h2>
          </div>
          <div className="content-wrapper">
            <div>
              <p>
                Vesta Property Management is committed to delivering the finest
                property management service for residential housing, serviced
                apartments, retail and mixed-use properties.
              </p>
              <p>
                We provide a one-stop service for international professional
                management services, covering the full range of estate
                management, maintenance, environmental protection, and clubhouse
                management.
              </p>
              <p>
                Our professional team goes above and beyond to deliver memories
                for our tenants and residents. We are proud of our human touch
                in creating environments for people to love where they live.
              </p>
            </div>
            <div className="values-container">
              <h4>Core Values</h4>
              <ul>
                <li>
                  Understanding the needs of individuals and communities, we put
                  the CUSTOMER first.
                </li>
                <li>
                  Thinking ahead and being proactive to ensure our PREPAREDNESS.
                </li>
                <li>Always look for ways to SURPASS expectations.</li>
                <li>
                  We value TEAMWORK and strive for EXCELLENCE in the development
                  of our businesses and communities.
                </li>
              </ul>
            </div>
          </div>
        </article>

        <article id="about-us" className="content-container about-us-container">
          <div className="content-wrapper">
            <h3>Myanmar’s #1 Property Manager</h3>
            <h2>ABOUT US</h2>
            <p>
              Since 2018, Vesta has been operating, overseeing, and leading
              residential and commercial communities in Myanmar. What sets us
              apart from our competitors is our commitment to our customers'
              comfort and quality lifestyle, as well as cutting-edge services
              that improve the quality of life. From customer service to
              engineering service, our all-round services are customized to meet
              different project needs for residents and visitors to have the
              best experience.
            </p>
            <p>
              At Vesta, we are always assessing and upgrading the services we
              provide our partners and residents in our pursuit in becoming
              Myanmar’s best property management firm. It is our mission to
              serve the community with integrity, enthusiasm, and professional
              devotion.
            </p>
          </div>
          <div className="content-wrapper">
            <div className="img-container">
              <img src={aboutImg} alt="" />
            </div>
          </div>
        </article>

        <article
          id="services"
          // ref={serviceRef}
          className="content-container services-container"
        >
          <div className="bg"></div>
          <div className="service-title">
            <h2>
              <span>OUR</span> SERVICES
            </h2>
          </div>
          <div className="service-body">
            {services.map((service, index) => (
              <Link to={`/service/${service.id}/en`} key={index}>
                <div className="service-card">
                  <h3>{service.title}</h3>
                  <p>{service.topic}</p>
                  <img
                    src={service.pictures[0]}
                    alt=""
                    className="service-img"
                  />
                </div>
              </Link>
            ))}
          </div>
        </article>

        {/* <section className="img-container service-lg-img-container">
          <img src={projectImg} alt="" />
        </section> */}

        <article id="client" className="content-container project-container">
          <div className="content-wrapper project-content">
            <div className="project-title ">
              <h3>Our Client</h3>
              <h2>PROJECTS</h2>
            </div>
            <p className="project-first-content">
              One of our greatest milestones is partnering with award-winning
              high-end mixed use development projects, The Central and The Park.
              We manage, operate, and oversee both residence and retail street.
              While we specialize in property management, we strive to create
              memorable experiences for both residents and visitors.
            </p>
          </div>
          <div className="slide-container">
            <ProjectsSlide lang="en" projects={projects} />
          </div>
        </article>

        {news.length > 0 && (
          <article id="news" className="content-container news-container">
            <h2>NEWS AND UPDATES</h2>
            <div className="slide-container">
              <NewsSlide news={news} />
            </div>
          </article>
        )}

        <article id="contact" className="content-container contact-container">
          <div className="content-wrapper">
            <h2>CONTACT</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur elit, sed do eiusmod tempor incididunt
            </p> */}
            <div>
              <h5>Enquiry Hotline</h5>
              <p>
                <a href="tel:+9514700170">(+95) 14700170</a> (Office Hours:
                08:30 to 17:30, Monday to Friday)
              </p>
            </div>
            <div>
              <h5>Email Address</h5>
              <a href="mailto:enquiry@vesta.com.mm">enquiry@vesta.com.mm</a>
            </div>
            {/* <div>
              <h5>Location Address</h5>
              <address>
                No. 7-8, Kabar Aye Pagoda Road, Yankin Township, Yangon
              </address>
            </div> */}
          </div>
          <div className="content-wrapper">
            <form>
              <div className="form-control">
                <label htmlFor="interested-service">Interested Service</label>
                <div className="home-form-control-group">
                  <select
                    name="interested-service"
                    id="interested-service"
                    value={values.interestedService}
                    onChange={handleChange("interestedService")}
                  >
                    <option value={""}></option>
                    <option value={"Property Management"}>
                      Property Management
                    </option>
                    <option value={"Engineering service"}>
                      Engineering service
                    </option>
                    <option value={"Housekeeping service"}>
                      Housekeeping service
                    </option>
                    <option value={"Security service"}>Security service</option>
                    <option value={"Customer service"}>Customer service</option>
                  </select>
                  {errors.interestedService && (
                    <span className="input-error-msg">
                      {errors.interestedService}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="name">Name</label>
                <div className="home-form-control-group">
                  <input
                    name="name"
                    id="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange("name")}
                  />
                  {errors.name && (
                    <span className="input-error-msg">{errors.name}</span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="company-name">Company Name</label>
                <div className="home-form-control-group">
                  <input
                    name="company-name"
                    id="company-name"
                    type="text"
                    value={values.companyName}
                    onChange={handleChange("companyName")}
                  />
                  {errors.companyName && (
                    <span className="input-error-msg">
                      {errors.companyName}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="phone-number">Phone Number</label>
                <div className="home-form-control-group">
                  <div className="phone-form-control-group">
                    <input
                      name="country-code"
                      id="conuntry-code"
                      type="tel"
                      value={values.countryCode}
                      onChange={handleChange("countryCode")}
                      placeholder="95"
                    />
                    <input
                      name="phone-number"
                      id="phone-number"
                      type="tel"
                      value={values.phone}
                      onChange={handleChange("phone")}
                    />
                  </div>

                  {errors.phone && (
                    <span className="input-error-msg">{errors.phone}</span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="email">Email</label>
                <div className="home-form-control-group">
                  <input
                    name="email"
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange("email")}
                  />
                  {errors.email && (
                    <span className="input-error-msg">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <button
                  onClick={handleCreate}
                  disabled={loading}
                  className={loading ? "loading-btn form-btn" : "btn form-btn"}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </article>

        <section id="location" className="img-container map-container">
          {window.innerWidth > 480 ? (
            <img src={mapImg} alt="" />
          ) : (
            <img src={mapImg2} alt="" />
          )}
        </section>
      </main>
      {showAlert.message && (
        <Alert message={showAlert.message} isError={showAlert.isError} />
      )}
      <Footer />
    </>
  );
};

export default Home;
