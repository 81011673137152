import axios from "axios";
import { BACKEND_URL } from "../utils/utils";
// const BACKEND_URL = "http://localhost:3001";
// const BACKEND_URL = "http://157.230.242.167:3001";

const getProjectsEn = async () => {
  const response = await axios.get(`${BACKEND_URL}/api/projects/en`);
  return response;
};

const getProjectsMm = async () => {
  const response = await axios.get(`${BACKEND_URL}/api/projects/mm`);
  return response;
};

const getProjectEn = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/projects/${id}/en`);
  return response;
};

const getProjectMm = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/projects/${id}/mm`);
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getProjectsEn,
  getProjectEn,
  getProjectsMm,
  getProjectMm,
};
