import axios from "axios";
import { BACKEND_URL } from "../utils/utils";

// const getImageUrl = async (token, name) => {
//   const config = { headers: { authorization: `Bearer ${token}` } };
//   const response = axios.get(`${BACKEND_URL}/api/upload/image/${name}`, config);
//   return response;
// };

// const getFileUrl = async (token, name) => {
//   const config = { headers: { authorization: `Bearer ${token}` } };
//   const response = axios.get(`${BACKEND_URL}/api/upload/file/${name}`, config);
//   return response;
// };

// const uploadImage = async (uri, data) => {
//   const config = {
//     headers: {
//       "Content-Type": "image/*",
//       "x-amz-acl": "public-read",
//     },
//   };
//   const response = await axios.put(uri, data, config);
//   return response;
// };

// const uploadFile = async (uri, data) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/pdf",
//       "x-amz-acl": "public-read",
//     },
//   };
//   const response = await axios.put(uri, data, config);
//   return response;
// };

// const deleteImage = async (token, data) => {
//   const config = { headers: { authorization: `Bearer ${token}` } };
//   const response = await axios.delete(
//     `${BACKEND_URL}/api/upload/image`,
//     config,
//     data
//   );
//   return response;
// };

// const deleteFile = async (token, data) => {
//   const config = { headers: { authorization: `Bearer ${token}` } };
//   const response = await axios.delete(
//     `${BACKEND_URL}/api/upload/file`,
//     config,
//     data
//   );
//   return response;
// };

const uploadImage = async (token, formData) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = axios.post(
    `${BACKEND_URL}/api/upload/image`,
    formData,
    config
  );
  return response;
};

const uploadFiles = async (token, formData) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = axios.post(
    `${BACKEND_URL}/api/upload/files`,
    formData,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadFiles,
  uploadImage,
};
