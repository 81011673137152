import uploadService from "../services/upload";
import { instanceToken } from "../utils/token";

// export const handleImgUpload = async (url, imageFile) => {
//   try {
//     await uploadService.uploadImage(url, imageFile);
//   } catch (error) {
//     console.log(error);
//   }
// };
// export const handleUpload = async (url, file) => {
//   try {
//     await uploadService.uploadFile(url, file);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const fetchImageUrl = async (name) => {
//   try {
//     const res = await uploadService.getImageUrl(instanceToken.token, name);
//     if (res.data) {
//       return {
//         url: res.data.imageUploadUrl,
//         name: `https://axra.sgp1.digitaloceanspaces.com/Vesta/${res.data.imageName}`,
//       };
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const fetchFileUrl = async (name) => {
//   try {
//     const res = await uploadService.getFileUrl(instanceToken.token, name);
//     if (res.data) {
//       return {
//         url: res.data.fileUploadUrl,
//         name: `https://axra.sgp1.digitaloceanspaces.com/Vesta/${res.data.fileName}`,
//       };
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const deleteImage = async (fileName) => {
//   try {
//     await uploadService.deleteImage(instanceToken.token, {
//       imageName: fileName,
//     });
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const deleteFile = async (fileName) => {
//   try {
//     await uploadService.deleteFile(instanceToken.token, {
//       imageName: fileName,
//     });
//   } catch (error) {
//     console.error(error);
//   }
// };

export const handleImgUpload = async (imgFile) => {
  try {
    const formData = new FormData();
    formData.append("image", imgFile);
    const response = await uploadService.uploadImage(
      instanceToken.token,
      formData
    );
    return response.data.filename;
  } catch (error) {
    console.log(error);
  }
};

export const handleMultiFileUpload = async (files) => {
  try {
    const formData = new FormData();
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      const file = files[i];
      formData.append("files", file);
    }
    const response = await uploadService.uploadFiles(
      instanceToken.token,
      formData
    );
    return response.data.filenames;
  } catch (error) {
    console.log(error);
  }
};

// export const BACKEND_URL = "http://localhost:3001";
export const BACKEND_URL = "https://api.vesta.com.mm";
