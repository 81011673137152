import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../styles/submit.css";
import Alert from "../../components/Alert";
import tenderService from "../../services/tender";
import { instanceToken } from "../../utils/token";
import { handleImgUpload, handleMultiFileUpload } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../../utils/utils";

const fileTypes = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const ndaFileTypes = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const imgTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

const Submit = () => {
  const { id } = useParams();
  const [dragActive, setDragActive] = useState(false);
  const [dragNdaActive, setDragNdaActive] = useState(false);
  const [dragImgActive, setDragImgActive] = useState(false);
  const [fileName, setFileName] = useState("");
  const [imgName, setImgName] = useState("");
  const [ndaName, setNdaName] = useState("");
  const [file, setFile] = useState(null);
  const [ndaFile, setNdaFile] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tender, setTender] = useState(null);

  const [showAlert, setShowAlert] = useState({
    message: "",
    isError: false,
  });
  const [values, setValues] = useState({
    fileUrl: "",
    imgUrl: "",
    ndaUrl: "",
    url: "",
  });
  const [errors, setErrors] = useState({});
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    if (!submited) {
      fetchSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tender) {
      fetchTendres();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTendres = async () => {
    try {
      const response = await tenderService.getTender(instanceToken.token, id);
      const tender = response.data;
      let image_urls = tender.pictures ? tender.pictures.split("||") : [];
      setTender({ ...tender, pictures: image_urls });
    } catch (error) {
      console.log(error);
    }
  };

  const fileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setFileName(e.target.files[0].name);
      checkFile(file);
    }
  };

  const imgSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setImgName(e.target.files[0].name);
      checkImgFile(file);
    }
  };

  const ndaFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setNdaName(e.target.files[0].name);
      checkNdaFile(file);
    }
  };

  const checkFile = (file) => {
    if (!fileTypes.includes(file.type)) {
      setErrors({ ...errors, file: "Please select PDF, Excel or Word file." });
      return;
    }
    if (file.size > 10485760 * 2.5) {
      setErrors({ ...errors, file: "File size must be smaller than 25MB" });
      return;
    }
    setFile(file);
  };

  const checkImgFile = (file) => {
    if (!imgTypes.includes(file.type)) {
      setErrors({
        ...errors,
        img: "Please select an image with JPEG or PNG format.",
      });
      return;
    }
    if (file.size > 10485760) {
      setErrors({ ...errors, img: "Image size must be smaller than 10MB." });
      return;
    }
    setImgFile(file);
  };

  const checkNdaFile = (file) => {
    if (!ndaFileTypes.includes(file.type)) {
      setErrors({ ...errors, file: "Please select fd PDF file." });
      return;
    }
    if (file.size > 10485760 * 25) {
      setErrors({ ...errors, file: "File size must be smaller than 10MB." });
      return;
    }
    setNdaFile(file);
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFileName(e.dataTransfer.files[0].name);
      checkFile(e.dataTransfer.files[0]);
    }
  };

  const handleNdaDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setNdaName(e.dataTransfer.files[0].name);
      checkNdaFile(e.dataTransfer.files[0]);
    }
  };

  const handleImgDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setImgName(e.dataTransfer.files[0].name);
      checkImgFile(e.dataTransfer.files[0]);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleNdaDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragNdaActive(true);
    } else if (e.type === "dragleave") {
      setDragNdaActive(false);
    }
  };

  const handleImgDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragImgActive(true);
    } else if (e.type === "dragleave") {
      setDragImgActive(false);
    }
  };

  const handleSubmit = async () => {
    setErrors({});
    let err = {};
    if (!imgFile) {
      err.img = "Please select an image with JPEG or PNG format.";
    }
    if (!values.url) {
      if (!file) {
        err.file = "Please select a PDF, Excel or Word file.";
      }
      if (!ndaFile) {
        err.nda = "Please select a PDF file.";
      }
    }

    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let data = values;
      // const imageName = "" + imgName.substring(0, imgName.lastIndexOf("."));
      if (fileName && ndaName) {
        // const fName = "" + fileName.substring(0, fileName.lastIndexOf("."));
        // const nName = "" + ndaName.substring(0, ndaName.lastIndexOf("."));
        // await fetchFileUrl(fName).then(async ({ url, name }) => {
        //   await handleUpload(url, file);
        //   data = { ...data, fileUrl: name };
        // });
        // await fetchFileUrl(nName).then(async ({ url, name }) => {
        //   await handleUpload(url, ndaFile);
        //   data = { ...data, ndaUrl: name };
        // });
        let filenames = await handleMultiFileUpload([file, ndaFile]);
        data = {
          ...data,
          fileUrl: `${BACKEND_URL}/static/files/${filenames[0]}`,
          ndaUrl: `${BACKEND_URL}/static/files/${filenames[1]}`,
        };
      }
      // await fetchImageUrl(imageName).then(async ({ url, name }) => {
      //   await handleImgUpload(url, imgFile);
      //   data = { ...data, imgUrl: name };
      // });
      const filename = await handleImgUpload(imgFile);
      data = { ...data, imgUrl: `${BACKEND_URL}/static/images/${filename}` };
      await tenderService.postSubmit(instanceToken.token, id, data);
      setValues({
        fileUrl: "",
        imgUrl: "",
        ndaUrl: "",
        url: "",
      });
      setSubmited(true);
      setShowAlert({
        message: "You have just submitted.",
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } catch (error) {
      console.log(error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  const fetchSubmit = async () => {
    try {
      const response = await tenderService.getSubmit(instanceToken.token, id);
      if (response.status === 200) {
        setSubmited(true);
      } else if (response.status === 204) {
        setSubmited(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="submit">
      <Header />
      <main>
        {!tender ? (
          <em>Loading...</em>
        ) : (
          <>
            <h1>Tender Submission</h1>
            {/* <div className="form-control-group">
          <h3>Title</h3>
          <div>
            <div className="form-control">
              <label>Price</label>
              <input id="price"></input>
            </div>
            <div className="form-control">
              <label>Price</label>
              <input id="price"></input>
            </div>
          </div>
        </div>
        <div className="form-control-group">
          <h3>Title</h3>
          <div>
            <div className="form-control">
              <label htmlFor="product_description">Product Description</label>
              <textarea id="product_description"></textarea>
            </div>
          </div>
        </div>
        <div className="form-control-group">
          <h3>Title</h3>
          <div>
            <div className="form-control">
              <label>Price</label>
              <input id="price"></input>
            </div>
            <div className="form-control">
              <label>Price</label>
              <input id="price"></input>
            </div>
            <div className="form-control">
              <label>Price</label>
              <input id="price"></input>
            </div>
            <div className="form-control">
              <label>Price</label>
              <input id="price"></input>
            </div>
            <div className="form-control">
              <label>Price</label>
              <input id="price"></input>
            </div>
          </div>
        </div> */}
            <div className="form-control-group">
              <h3>{tender.name}</h3>
              <div>
                <div className="form-group-upload">
                  <input
                    id="img"
                    name="img"
                    type="file"
                    accept={imgTypes}
                    onChange={imgSelect}
                    className={errors.img && "input-error"}
                  />
                  {errors.img && (
                    <span className="input-error-msg">{errors.img}</span>
                  )}
                  <label htmlFor="img">
                    <div
                      className={
                        dragImgActive ? "drop_zone drop-active" : "drop_zone"
                      }
                      onDragOver={handleImgDrag}
                      onDrop={handleImgDrop}
                    >
                      <p>
                        {imgName ? (
                          imgName
                        ) : (
                          <>
                            <span>
                              Drop your company registration form image here.
                            </span>
                            <br />
                            <span className="placeholder">
                              Please do not exceed 10 MB your document file.
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  </label>
                </div>
                <div className="form-group-upload">
                  <input
                    id="file"
                    name="file"
                    type="file"
                    accept={fileTypes}
                    onChange={fileSelect}
                    className={errors.file && "input-error"}
                  />
                  {errors.file && (
                    <span className="input-error-msg">{errors.file}</span>
                  )}
                  <label htmlFor="file">
                    <div
                      className={
                        dragActive ? "drop_zone drop-active" : "drop_zone"
                      }
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    >
                      <p>
                        {fileName ? (
                          fileName
                        ) : (
                          <>
                            <span>
                              Drop your complete Tender document file here.
                            </span>
                            <br />
                            <span className="placeholder">
                              Please do not exceed 25 MB your document file.
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  </label>
                </div>
                <div className="form-group-upload">
                  <input
                    id="ndaFile"
                    name="ndaFile"
                    type="file"
                    accept={ndaFileTypes}
                    onChange={ndaFileSelect}
                    className={errors.nda && "input-error"}
                  />
                  {errors.nda && (
                    <span className="input-error-msg">{errors.nda}</span>
                  )}
                  <label htmlFor="ndaFile">
                    <div
                      className={
                        dragNdaActive ? "drop_zone drop-active" : "drop_zone"
                      }
                      onDragOver={handleNdaDrag}
                      onDrop={handleNdaDrop}
                    >
                      <p>
                        {ndaName ? (
                          ndaName
                        ) : (
                          <>
                            <span>
                              Drop signed Anti-Bribery and Anti-Corruption,
                              Non-Disclosure Agreement forms here.
                            </span>
                            <br />
                            <span className="placeholder">
                              Please do not exceed 25 MB your document file.
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  </label>
                </div>
                <div className="option-content">
                  <p>Or</p>
                  <p>You can also share your documents with drive link</p>
                </div>
                <div className="form-control">
                  <input
                    id="url"
                    name="url"
                    type="text"
                    onChange={(e) =>
                      setValues({ ...values, url: e.target.value })
                    }
                    placeholder="Paste your link here."
                  />
                </div>
              </div>
            </div>
            <div className="form-control-group btn-group">
              <button className="cancel-btn">Cancel</button>
              {submited ? (
                <button disabled className={"submit-btn loading-btn"}>
                  Already Submitted
                </button>
              ) : (
                <button
                  disabled={loading}
                  className={loading ? "submit-btn loading-btn" : "submit-btn"}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </div>
            {submited && (
              <>
                <p>"Already submitted"</p>
                <p>
                  Did you make a mistake or incomplete document in tender
                  submission?{" "}
                  <a href="mailto:vestaqs@vesta.com.mm">
                    Please contact us via vestaqs@vesta.com.mm
                  </a>
                </p>
              </>
            )}
          </>
        )}
      </main>
      {showAlert.message && (
        <Alert message={showAlert.message} isError={showAlert.isError} />
      )}
      <Footer />
    </div>
  );
};

export default Submit;
