import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../styles/slide.css";
import { Pagination } from "swiper";
// import newsService from "../services/news";

const NewsSlide = ({ news }) => {
  // const [news, setNews] = useState(null);
  // useEffect(() => {
  //   if (!news) {
  //     fetchNews();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const fetchNews = async () => {
  //   try {
  //     const response = await newsService.getNews();
  //     let news = response.data.news;
  //     setNews(news);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // if (!news) {
  //   return <em>Loading...</em>;
  // }

  return (
    <>
      <Swiper
        slidesPerView={1}
        // centeredSlidesBounds={true}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="NewsSwiper"
        breakpoints={{
          540: {
            slidesPerView: 3,
          },
          1550: {
            slidesPerView: 4,
          },
        }}
      >
        {news.map((n, index) => (
          <SwiperSlide key={index}>
            <div className="img-container slide-img">
              <img src={n.picture} alt="" />
            </div>
            <div className="slide-content">
              <h2>{n.title}</h2>
              <p>{n.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default NewsSlide;
