import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../styles/tender.css";
// import tendersImg from "../../asset/images/tenders.png";
import folderIcon from "../../asset/icons/folder.png";
import tenderService from "../../services/tender";
import { instanceToken } from "../../utils/token";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import TenderSlide from "../../components/TenderSlide";
import pdf from "../../asset/T&C.pdf";

const Tender = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tender, setTender] = useState(null);
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    if (!tender) {
      fetchTendres();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tender) {
      if (checkSubmited()) {
        setSubmited(true);
      } else {
        setSubmited(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tender]);

  const fetchTendres = async () => {
    try {
      const response = await tenderService.getTender(instanceToken.token, id);
      const tender = response.data;
      let image_urls = tender.pictures ? tender.pictures.split("||") : [];
      setTender({ ...tender, pictures: image_urls });
    } catch (error) {
      console.log(error);
    }
  };

  const goto = () => {
    navigate(`/auth/submit/${id}`);
  };

  const checkSubmited = () => {
    return tender.users?.find((u) => u.id === instanceToken.id);
  };

  return (
    <div id="tender">
      <Header />
      <main>
        {!tender ? (
          <em>Loading...</em>
        ) : (
          <>
            <div className="tender-img-container">
              {/* <img src={tendersImg} alt="" /> */}
              <TenderSlide images={tender.pictures} />
            </div>
            <h1>{tender.name}</h1>
            <div>
              <div className="tender-infos">
                <div className="tender-info">
                  <p className="tender-info-label">Date Of Posting</p>
                  <p className="tender-info-content">
                    {moment(tender.start_date).format("dddd, MMMM Do YYYY")}
                  </p>
                </div>
                <div className="tender-info">
                  <p className="tender-info-label">Date Of Site Survey</p>
                  <p className="tender-info-content">
                    {moment(tender.interviewing_date).format(
                      "dddd, MMMM Do YYYY"
                    )}
                  </p>
                </div>
                <div className="tender-info">
                  <p className="tender-info-label">Last Date Of Submission</p>
                  <p className="tender-info-content">
                    {moment(tender.end_date).format("dddd, MMMM Do YYYY")}
                  </p>
                </div>

                <div className="tender-info">
                  <p className="tender-info-label">Final Contract Award</p>
                  <p className="tender-info-content">
                    {moment(tender.announcement_date).format(
                      "dddd, MMMM Do YYYY"
                    )}
                  </p>
                </div>
                <div className="tender-info">
                  <p className="tender-info-label">Email For Information</p>
                  <p className="tender-info-content">
                    <a href="mailto:vestaqs@vesta.com.mm">
                      vestaqs@vesta.com.mm
                    </a>
                  </p>
                </div>
              </div>
              <p
                className="tender-content"
                dangerouslySetInnerHTML={{ __html: tender.body }}
              ></p>
              {new Date(tender.end_date).getTime() >= new Date().getTime() && (
                <>
                  {tender.document_url && (
                    <a
                      href={tender.document_url}
                      target="_blank"
                      rel="noreferrer"
                      className="download-content"
                    >
                      <img
                        className="folder-icon"
                        src={folderIcon}
                        alt="folder icon"
                      />
                      Download{" "}
                      {tender.document_url.substring(
                        tender.document_url.lastIndexOf("/") + 1,
                        tender.document_url.length
                      )}{" "}
                      File
                    </a>
                  )}

                  {tender.nda_url && (
                    <a
                      href={tender.nda_url}
                      target="_blank"
                      rel="noreferrer"
                      className="download-content"
                    >
                      <img
                        className="folder-icon"
                        src={folderIcon}
                        alt="folder icon"
                      />
                      Download{" "}
                      {tender.nda_url.substring(
                        tender.nda_url.lastIndexOf("/") + 1,
                        tender.nda_url.length
                      )}{" "}
                      Form
                    </a>
                  )}
                </>
              )}

              <div className="tender-footer">
                {submited ? (
                  <>
                    <p>"Already submitted"</p>
                    <p>
                      Did you make a mistake or incomplete document in tender
                      submission?{" "}
                      <a href="mailto:vestaqs@vesta.com.mm">
                        Please contact us via vestaqs@vesta.com.mm
                      </a>
                    </p>
                  </>
                ) : new Date(tender.end_date).getTime() <
                  new Date().getTime() ? (
                  "Closed"
                ) : (
                  <button className="tender-btn" onClick={goto}>
                    Tender Submission
                  </button>
                )}
                <a href={pdf} target="_blank" rel="noreferrer">
                  Terms & Conditions
                </a>
                {/* <a href="/">Accepted policy</a> */}
              </div>
            </div>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Tender;
