import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/notFound.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div id="not-found">
      <Header />
      <main>
        <h1>Page Not Found</h1>
        <Link to="/">Go to Home</Link>
      </main>
      <Footer />
    </div>
  );
};

export default NotFound;
