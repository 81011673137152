import React, { useState, useRef, useEffect } from "react";
import menuImg from "../asset/images/menu.svg";
import { CSSTransition } from "react-transition-group";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import "../styles/header.css";
import BannerSlide from "./BannerSlide";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../asset/images/logo-2.png";

const Header = () => {
  // const navigate = useNavigate();
  // const location = useLocation();
  gsap.registerPlugin(ScrollToPlugin);
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const nodeRef = useRef(null);

  const [lang, setLang] = useState("");
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const langJSON = window.localStorage.getItem("Vesta_lang");
    const loggedJSON = window.localStorage.getItem("Vesta_loggedUser");
    if (langJSON) {
      setLang(langJSON);
    }
    if (loggedJSON) {
      setLogged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleLogout = () => {
    window.localStorage.removeItem("Vesta_loggedUser");
    setLogged(false);
    navigate("/");
  };

  const changeLang = (lang) => {
    window.localStorage.setItem("Vesta_lang", lang);
    if (lang === "mm") {
      navigate(`/${lang}`);
    } else {
      navigate(`/`);
    }
  };

  // const scrollTo = (position) => {
  //   if (location.pathname !== "/") {
  //     // navigate(`/#${position}`);
  //     navigate("/", { state: position });
  //     return;
  //   }
  //   switch (position) {
  //     case "home":
  //       gsap.to(window, {
  //         duration: 1,
  //         scrollTo: { y: "#home", offsetY: 70 },
  //         ease: "back",
  //       });
  //       break;
  //     case "about":
  //       gsap.to(window, {
  //         duration: 1.2,
  //         scrollTo: { y: "#about-us", offsetY: 70 },
  //         ease: "back",
  //       });
  //       break;
  //     case "services":
  //       gsap.to(window, {
  //         duration: 1.4,
  //         scrollTo: { y: "#services", offsetY: 70 },
  //         ease: "back",
  //       });
  //       break;
  //     case "client":
  //       gsap.to(window, {
  //         duration: 1.6,
  //         scrollTo: { y: "#client", offsetY: 70 },
  //         ease: "back",
  //       });
  //       break;
  //     case "news":
  //       gsap.to(window, {
  //         duration: 1.8,
  //         scrollTo: { y: "#news", offsetY: 70 },
  //         ease: "back",
  //       });
  //       break;
  //     case "contact":
  //       gsap.to(window, {
  //         duration: 2,
  //         scrollTo: { y: "#contact", offsetY: 70 },
  //         ease: "back",
  //       });
  //       break;
  //     case "location":
  //       gsap.to(window, {
  //         duration: 2.2,
  //         scrollTo: { y: "#location", offsetY: 70 },
  //         ease: "power3",
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      <header>
        <Link to="/">
          {/* <h1>
            <span className="vesta">V</span>esta
          </h1> */}
          <img className="logo" src={logo} alt="vesta propery management" />
        </Link>
        <button
          className={
            !open
              ? "menu-btn-container show-btn"
              : "menu-btn-container hide-btn"
          }
          onClick={() => {
            setOpen(!open);
          }}
        >
          <img src={menuImg} alt="" />
        </button>
        <CSSTransition
          nodeRef={nodeRef}
          in={open}
          timeout={1000}
          classNames="menu"
          unmountOnExit
        >
          <div ref={nodeRef} className="menu-container">
            {/* <div
              className="menu-fill"
              onClick={() => {
                setOpen(!open);
              }}
            ></div> */}
            <div className="menu-content-container">
              <button
                onClick={() => {
                  setOpen(!open);
                }}
                className="close"
              >
                X
              </button>
              <ul>
                <li>
                  {/* <button onClick={() => scrollTo("home")}>Home</button> */}
                  <HashLink to={lang === "mm" ? "/mm/#home" : "/#home"} smooth>
                    Home
                  </HashLink>
                </li>
                <li>
                  {/* <button onClick={() => scrollTo("about")}>About Us</button> */}
                  <HashLink
                    to={lang === "mm" ? "/mm/#about-us" : "/#about-us"}
                    smooth
                  >
                    About Us
                  </HashLink>
                </li>
                <li>
                  {/* <button onClick={() => scrollTo("services")}>Services</button> */}
                  <HashLink
                    to={lang === "mm" ? "/mm/#services" : "/#services"}
                    smooth
                  >
                    Services
                  </HashLink>
                </li>
                <li>
                  {/* <button onClick={() => scrollTo("client")}>
                    Our Clinets
                  </button> */}
                  <HashLink
                    to={lang === "mm" ? "/mm/#client" : "/#client"}
                    smooth
                  >
                    Our Clients
                  </HashLink>
                </li>
                {/* <li>
                  <HashLink to={lang === "mm" ? "/mm/#news" : "/#news"} smooth>
                    News & Updates
                  </HashLink>
                </li> */}
                <li>
                  {/* <button onClick={() => scrollTo("contact")}>Contact</button> */}
                  <HashLink
                    to={lang === "mm" ? "/mm/#contact" : "/#contact"}
                    smooth
                  >
                    Contact
                  </HashLink>
                </li>
                <li>
                  {/* <button onClick={() => scrollTo("location")}>Location</button> */}
                  <HashLink
                    to={lang === "mm" ? "/mm/#location" : "/#location"}
                    smooth
                  >
                    Location
                  </HashLink>
                </li>
                <li>
                  {/* <button onClick={() => navigate("/tenders")}>E-Tender</button> */}
                  <Link to="/tenders">E-Tenders</Link>
                </li>
                {logged && (
                  <li>
                    <Link to="/auth/submitted">Submitted Tenders</Link>
                  </li>
                )}
              </ul>
              <div className="header-footer">
                {logged ? (
                  <button onClick={handleLogout}>Logout</button>
                ) : (
                  <button onClick={() => navigate("/signin")}>Login</button>
                )}
                <div className="lang-select">
                  <button onClick={() => changeLang("en")}>EN</button>
                  <button onClick={() => changeLang("mm")}>MM</button>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </header>
      <section className="img-container banner">
        <BannerSlide />
      </section>
      <button
        id="float-btn"
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        Up
      </button>
    </>
  );
};

export default Header;
