import React, { useEffect, useState } from "react";
import "../styles/home.css";
import aboutImg from "../asset/images/about_bg.png";
import mapImg from "../asset/images/map.jpg";
import mapImg2 from "../asset/images/map2.jpg";
// import projectImg from "../asset/images/project.png";
import NewsSlide from "../components/NewsSlide";
import Footer from "../components/Footer";
import Alert from "../components/Alert";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Header from "../components/Header";
import ProjectsSlide from "../components/ProjectsSlide";
import serviceService from "../services/service";
import { Link } from "react-router-dom";
import contactService from "../services/contact";
import newsService from "../services/news";
import projectService from "../services/project";

const HomeMm = () => {
  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
  const [services, setServices] = useState(null);
  const [news, setNews] = useState(null);
  const [projects, setProjects] = useState(null);

  const [values, setValues] = useState({
    interestedService: "",
    name: "",
    companyName: "",
    phone: "",
    email: "",
    countryCode: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    message: "",
    isError: false,
  });

  useEffect(() => {
    if (!services) {
      fetchServices();
    }
    if (!news) {
      fetchNews();
    }
    if (!projects) {
      fetchProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNews = async () => {
    try {
      const response = await newsService.getNews();
      let news = response.data.news;
      setNews(news);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjects = async () => {
    try {
      let response;
      response = await projectService.getProjectsMm();
      let projects = response.data.projects;
      projects = await projects.map((project) => {
        let image_urls = project.pictures ? project.pictures.split("||") : [];
        return { ...project, pictures: image_urls };
      });
      setProjects(projects);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setErrors({});
    let err = {};
    if (!values.interestedService) {
      err.interestedService = "Interested Service is required";
    }
    if (!values.name) {
      err.name = "Name is required";
    }
    if (!values.companyName) {
      err.companyName = "Company Name is required";
    }
    if (!values.countryCode || !values.phone) {
      err.phone = "Phone Number is required";
    }
    if (isNaN(values.phone || isNaN(values.countryCode))) {
      err.phone = "Phone Number must be numbers.";
    }
    if (!values.email) {
      err.email = "Email is required";
    }
    if (
      values.email &&
      // eslint-disable-next-line no-useless-escape
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
    ) {
      err.email = "Enter correct email";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      await contactService.postContact({
        name: values.name,
        email: values.email,
        phone: values.countryCode + values.phone,
        companyName: values.companyName,
        interestedService: values.interestedService,
      });
      setValues({
        interestedService: "",
        name: "",
        companyName: "",
        phone: "",
        email: "",
        countryCode: "",
      });
      setShowAlert({
        message: "You have just sent your contact. Thank you!",
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } catch (error) {
      console.log(error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  const fetchServices = async () => {
    try {
      const response = await serviceService.getServicesMm();
      let services = response.data.services;
      services = await services.map((service) => {
        let image_urls = service.pictures ? service.pictures.split("||") : [];
        return { ...service, pictures: image_urls };
      });
      setServices(services);
    } catch (error) {
      console.log(error);
    }
  };

  if (!services || !news || !projects) {
    return <div>Loading</div>;
  }

  return (
    <>
      <Header />
      <main id="mm">
        <article id="home" className="content-container welcome-container">
          <div className="content-wrapper">
            <h3 id="test">
              <span className="vesta">V</span>ESTA PROPERTY MANAGEMENT
            </h3>
            <h2>မှ ကြိုဆိုပါသည်။</h2>
          </div>
          <div className="content-wrapper">
            <div>
              <p>
                Vesta Property Management သည် အဆင့်မြင့်လူနေအိမ်ရာများ၊
                တိုက်ခန်းများ၊ ဈေးဝယ်စင်တာများ အစရှိသည်တို့ကို အကောင်းဆုံးသော
                စီမံခန့်ခွဲမှုဆိုင်ရာ ဝန်ဆောင်များ ပေးလျက်ရှိပါသည်။
              </p>
              <p>
                နိုင်ငံတကာအဆင့်မှီ Professional ဝန်ဆောင်မှုများဖြင့်
                အိမ်ရာစီမံခန့်ခွဲမှု၊ ပြုပြင်မွမ်းမံမှုများ၊
                ပတ်ဝန်းကျင်ထိန်းသိမ်းမှုများ၊ Clubhouse စီမံခန့်ခွဲမှုများကို
                အကောင်းမွန်ဆုံး ဆောင်ရွက်ပေးလျက်ရှိပါသည်။ လူကြီးမင်းတို့အတွက်
                အမှတ်တရ ဖြစ်စေမည့် အကောင်းဆုံးသော ဝန်ဆောင်မှုများဖြင့်
                ကျွန်တော်များ
              </p>
              <p>
                Vesta Property Management အဖွဲ့အစည်းတွင်
                တာဝန်ထမ်းဆောင်လျက်ရှိသော ကျွမ်းကျင်ဝန်ထမ်းများမှ
                လိုအပ်ချက်များအတွက် ဆောင်ရွက်ပေးမည် ဖြစ်ပါသည်။
                လူကြီးမင်းတို့နေထိုင်ရာ နေရာအား အစဉ် ကြိုက်နှစ်သက်စေရန်
                ဖန်တီးပုံဖော်ရခြင်းအတွက် ဂုဏ်ယူမိပါသည်။
              </p>
            </div>
            <div className="values-container">
              <h4>အဓိကကျသော တန်ဖိုးများ</h4>
              <ul>
                <li>
                  Customer First ဆိုသည့် စာသားအတိုင်း တစ်ယောက်ချင်းဆီ၏
                  လိုအပ်ချက်များ၊ အသင်းအဖွဲ့၏လိုအပ်ချက်များ
                  ကောင်းမွန်စွာနားလည်ခြင်း။
                </li>
                <li>
                  အတွေးအခေါ်ကောင်းများပါဝင်တဲ့ တိကျသေချာသော ကောင်းမွန်သော
                  ဝန်ဆောင်မှုပေးနိုင်ခြင်း။
                </li>
                <li>
                  မျှော်မှန်းထားသည်ထက် ပိုမိုကောင်းမွန်စွာ
                  ဝန်ဆောင်မှုပေးနိုင်ခြင်း။
                </li>
                <li>
                  ခိုင်မာသော အသင်းအဖွဲ့ စွမ်းရည်ကို တန်ဖိုးထားပြီး
                  စွမ်းဆောင်ရည်အကောင်းဆုံး အရည်အသွေးများဖြင့်
                  အသိုက်အမြုံတစ်ခုကို ဖန်တီးပေးနိုင်ခြင်း။
                </li>
              </ul>
            </div>
          </div>
        </article>

        <article id="about-us" className="content-container about-us-container">
          <div className="content-wrapper">
            <h3>မြန်မာနိုင်ငံ၏ နံပါတ် (၁) Property Manager</h3>
            <h2>ကျွန်ပ်တို့အကြောင်း</h2>
            <p>
              Vesta property Management သည် ၂၀၁၈ မှစတင်၍ မြန်မာနိုင်ငံတွင်
              အခြေစိုက်ဝန်ဆောင်မှုများပေးနေပါသည်။ ကျွန်တော်တို့၏
              ပြိုင်ဘက်များထက်ပိုမိုထူးခြားစေနိုင်သည်မှာ Vesta သည် ခေတ်မှီ၍
              လူကြီးမင်းတို့အတွက် သက်သောင့်သက်သာရှိစေမည့် နေထိုင်မှုပုံစံ နှင့်
              အဆင့်မြင့် လူနေမှု စနစ်ကို ပေးစွမ်းနိုင်သလို လူကြီးမင်းတို့၏
              ဘဝတန်ဖိုးဒီထက်မက မြင့်မားစေရန်အတွက် ခေတ်မှီဆန်းသစ်သော
              ဝန်ဆောင်မှုများကိုလည်း ဆောင်ရွက်ပေးနေပါသည်။ လူကြီးမင်းတို့အတွက်
              customer service မှသည် engineering services အထိ နေထိုင်သောလူများ၏
              လိုအပ်ချက်နှင့်အညီ ဒီဇိုင်းဖန်တီးတည်ဆောက်ထားပြီး လာရောက်လည်ပတ်သော
              သူများအတွက်လည်း အကောင်းဆုံးသော အတွေ့အကြုံများကိုပေးနိုင်အောင်
              ဝန်ဆောင်မှုပေးထားပါသည်။
            </p>
            <p>
              Vesta တွင် ပြောင်းလဲနေသော မြန်မာနိုင်ငံ၏ Property Management Trend
              နှင့်အညီ အမြဲ Upgrade လုပ်ပေးနေပြီး ကျွန်တော်တို့၏ mission
              နှင့်အညီ တီတွင်ကြံဆမှု၊ အလေးအနက်ထားဆောင်ရွက်မှု နှင့် professional
              ဆန်သော ကြိုးပမ်းမှုများကို ဆောင်ရွက်နေပါသည်။
            </p>
          </div>
          <div className="content-wrapper">
            <div className="img-container">
              <img src={aboutImg} alt="" />
            </div>
          </div>
        </article>

        <article
          id="services"
          // ref={serviceRef}
          className="content-container services-container"
        >
          <div className="bg"></div>
          <div className="service-title">
            <h2>သုံးစွဲသူဝန်ဆောင်မှုများ</h2>
          </div>
          <div className="service-body">
            {services.map((service, index) => (
              <Link to={`/service/${service.id}/mm`} key={index}>
                <div className="service-card">
                  <h3>{service.title}</h3>
                  <p>{service.topic}</p>
                  <img
                    src={service.pictures[0]}
                    alt=""
                    className="service-img"
                  />
                </div>
              </Link>
            ))}
          </div>
        </article>
        {/* 
        <section className="img-container service-lg-img-container">
          <img src={projectImg} alt="" />
        </section> */}

        <article id="client" className="content-container project-container">
          <div className="content-wrapper project-content">
            <div className="project-title ">
              <h3>ဝန်ဆောင်မှုပေးလျက်ရှိသော</h3>
              <h2>စီမံကိန်းများ</h2>
            </div>
            <p className="project-first-content">
              ကျွန်တော်တို့၏ အကြီးမားဆုံး ရည်မှန်းချက်များထဲမှ တစ်ခုဖြစ်သည့်
              အရာသည် အောင်မြင်မှုဆုလာဒ်များစွာ ဆွတ်ခူးထားနိုင်သော အိမ်ရာ
              စီမံကိန်းများ နှင့် အတူတကွ တွဲဖက်လုပ်ကိုင်ရန်ဖြစ်ရာ ယခုအခါ The
              Central နှင့် The Park စသည့် အဆင့်မြင့်စီမံကိန်းများ အား
              အဆင့်မြင့်လူနေအိမ်ရာစီမံခန့်ခွဲရေး နှင့် ဈေးဝယ်စင်တာဆိုင်ရာ
              စီမံခန့်ခွဲရေး အစရှိသည့် အကောင်းဆုံးဝန်ဆောင်မှုများ
              ပေးလျက်ရှိပါသည်။ ကျွန်တော်တို့ ဝန်ဆောင်မှုပေးလျက်ရှိသော
              အိမ်ရာစီမံကိန်းများ နှင့် ဈေးဝယ်စင်တာများသို့ လာရောက်သော
              ဧည့်သည်များ နှင့် နေထိုင်လျက်ရှိသော လူကြီးမင်းတို့အား အမှတ်ရဖွယ်
              ဝန်ဆောင်မှုများ ဖန်တီးပေးလျက်ရှိပါသည်။
            </p>
          </div>
          <div className="slide-container">
            <ProjectsSlide lang="mm" projects={projects} />
          </div>
        </article>

        {news.length > 0 && (
          <article id="news" className="content-container news-container">
            <h2>သတင်းများ</h2>
            <div className="slide-container">
              <NewsSlide news={news} />
            </div>
          </article>
        )}

        <article id="contact" className="content-container contact-container">
          <div className="content-wrapper">
            <h2>ဆက်သွယ်ရန်</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur elit, sed do eiusmod tempor incididunt
            </p> */}
            <div>
              <h5>Enquiry Hotline</h5>
              <p>
                <a href="tel:+9514700170">(+95) 14700170</a> (Office Hours:
                08:30 to 17:30, Monday to Friday)
              </p>
            </div>
            <div>
              <h5>Email Address</h5>
              <a href="mailto:enquiry@vesta.com.mm">enquiry@vesta.com.mm</a>
            </div>
            {/* <div>
              <h5>Location Address</h5>
              <address>
                No. 7-8, Kabar Aye Pagoda Road, Yankin Township, Yangon
              </address>
            </div> */}
          </div>
          <div className="content-wrapper">
            <form>
              <div className="form-control">
                <label htmlFor="interested-service">Interested Service</label>
                <div className="home-form-control-group">
                  <select
                    name="interested-service"
                    id="interested-service"
                    value={values.interestedService}
                    onChange={handleChange("interestedService")}
                  >
                    <option value={""}></option>
                    <option value={"Property Management"}>
                      Property Management
                    </option>
                    <option value={"Engineering service"}>
                      Engineering service
                    </option>
                    <option value={"Housekeeping service"}>
                      Housekeeping service
                    </option>
                    <option value={"Security service"}>Security service</option>
                    <option value={"Customer service"}>Customer service</option>
                  </select>
                  {errors.interestedService && (
                    <span className="input-error-msg">
                      {errors.interestedService}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="name">Name</label>
                <div className="home-form-control-group">
                  <input
                    name="name"
                    id="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange("name")}
                  />
                  {errors.name && (
                    <span className="input-error-msg">{errors.name}</span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="company-name">Company Name</label>
                <div className="home-form-control-group">
                  <input
                    name="company-name"
                    id="company-name"
                    type="text"
                    value={values.companyName}
                    onChange={handleChange("companyName")}
                  />
                  {errors.companyName && (
                    <span className="input-error-msg">
                      {errors.companyName}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="phone-number">Phone Number</label>
                <div className="home-form-control-group">
                  <div className="phone-form-control-group">
                    <input
                      name="country-code"
                      id="conuntry-code"
                      type="tel"
                      value={values.countryCode}
                      onChange={handleChange("countryCode")}
                      placeholder="95"
                    />
                    <input
                      name="phone-number"
                      id="phone-number"
                      type="tel"
                      value={values.phone}
                      onChange={handleChange("phone")}
                    />
                  </div>

                  {errors.phone && (
                    <span className="input-error-msg">{errors.phone}</span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="email">Email</label>
                <div className="home-form-control-group">
                  <input
                    name="email"
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange("email")}
                  />
                  {errors.email && (
                    <span className="input-error-msg">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="form-control">
                <button
                  onClick={handleCreate}
                  disabled={loading}
                  className={loading ? "loading-btn form-btn" : "btn form-btn"}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </article>

        <section id="location" className="img-container map-container">
          {window.innerWidth > 480 ? (
            <img src={mapImg} alt="" />
          ) : (
            <img src={mapImg2} alt="" />
          )}
        </section>
      </main>
      {showAlert.message && (
        <Alert message={showAlert.message} isError={showAlert.isError} />
      )}
      <Footer />
    </>
  );
};

export default HomeMm;
