import React, { useState } from "react";
import "../styles/signup.css";
import { useNavigate } from "react-router-dom";
import loginService from "../services/login";
import Alert from "../components/Alert";
import jwt from "jwt-decode";
import { Link } from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    message: "",
    isError: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleLogin = async () => {
    setErrors({});
    let err = {};
    if (!values.email) {
      err.email = "Email field is required";
    }
    if (!values.password) {
      err.password = "Password field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      const response = await loginService.login({
        email: values.email,
        password: values.password,
      });
      const decodedToken = jwt(response.data.token);
      const data = JSON.stringify({
        decodedToken,
        token: response.data.token,
      });
      window.localStorage.setItem("Vesta_loggedUser", data);
      navigate("/tenders");
    } catch (error) {
      if (error.response) {
        setShowAlert({ message: error.response.data.error, isError: true });
        setTimeout(() => {
          setShowAlert({ message: "", isError: false });
        }, 4000);
      } else {
        setShowAlert({ message: "Error on server", isError: true });
        setTimeout(() => {
          setShowAlert({ message: "", isError: false });
        }, 4000);
      }
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  return (
    <main id="sign-in">
      <div className="form-container">
        <article className="form-content">
          <h1>Sign In</h1>
          <p>
            Please enter your detail to sign in and be part of our great
            community
          </p>
          <div>
            <p>Create an account?</p>
            <button onClick={() => navigate("/signup")}>Sign Up</button>
          </div>
        </article>
        <div className="form-body">
          <div className="form-control">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              id="email"
              type="email"
              value={values.email}
              onChange={handleChange("email")}
            />
            {errors.email && (
              <span className="input-error-msg">{errors.email}</span>
            )}
          </div>
          <div className="form-control">
            <label htmlFor="password">Password</label>
            <input
              name="password"
              id="password"
              type="password"
              value={values.password}
              onChange={handleChange("password")}
            />
            {errors.password && (
              <span className="input-error-msg">{errors.password}</span>
            )}
          </div>
          <div className="form-control">
            <button
              onClick={handleLogin}
              disabled={loading}
              className={loading ? "loading-btn" : ""}
            >
              SIGN IN
            </button>
          </div>
          <Link to="/forgot" id="forgot-text">
            Forgot password?
          </Link>
        </div>
      </div>
      <div className="go-home">
        <Link to="/">&#8592;</Link>
      </div>
      {showAlert.message && (
        <Alert message={showAlert.message} isError={showAlert.isError} />
      )}
    </main>
  );
};

export default SignIn;
