import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../styles/success.css";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  return (
    <div id="success">
      <Header />
      <main>
        <div className="card">
          <h1>THANK YOU!</h1>
          <p>You tender is sucessfully submitted.</p>
          <p>We will sent you a confirmation email.</p>
          <p>Keep in touch with your box.</p>
          <button onClick={() => navigate("/")}>Continue</button>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Success;
