import React from "react";
import "../styles/alert.css";

const Alert = ({ isError, message }) => {
  return (
    <div
      className={isError ? "alert-container error-alert" : "alert-container"}
    >
      <p>{message}</p>
    </div>
  );
};

export default Alert;
