import axios from "axios";
import { BACKEND_URL } from "../utils/utils";
// const BACKEND_URL = "http://localhost:3001";
// const BACKEND_URL = "http://157.230.242.167:3001";

const getUser = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}/api/users/${id}`, config);
  return response;
};

const getSubmittedTenders = async (token) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/users/submitted`,
    config
  );
  return response;
};

const postUser = async (data) => {
  const response = await axios.post(`${BACKEND_URL}/api/users`, data);
  return response;
};

const postOTP = async (data) => {
  const response = await axios.post(`${BACKEND_URL}/api/users/forgot`, data);
  return response;
};

const putChangePassword = async (data, id) => {
  const response = await axios.put(
    `${BACKEND_URL}/api/users/password/${id}`,
    data
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUser,
  postUser,
  getSubmittedTenders,
  postOTP,
  putChangePassword,
};
