import { useNavigate, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import userService from "../../services/user";
import { initToken } from "../../utils/token";

function Auth() {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const loggedJSON = window.localStorage.getItem("Vesta_loggedUser");
    if (loggedJSON) {
      const parsedJSON = JSON.parse(loggedJSON);
      fetchAuth(parsedJSON.token, parsedJSON.decodedToken.id).then(() => {
        initToken(parsedJSON.token, parsedJSON.decodedToken.id);
      });
    } else {
      navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAuth = async (token, id) => {
    try {
      const res = await userService.getUser(token, id);
      setAuth(res.data);
    } catch (error) {
      if (
        error?.response.data.error === "token invalid" ||
        error?.response.data.error === "token expired"
      ) {
        navigate("/signin");
      }
    }
  };

  if (!auth) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default Auth;
