import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import HomeMm from "./pages/HomeMm";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Tenders from "./pages/Tenders";
import Auth from "./pages/auth/Auth";
import Tender from "./pages/auth/Tender";
import Submit from "./pages/auth/Submit";
import Success from "./pages/auth/Success";
import Submitted from "./pages/auth/Submitted";
import Service from "./pages/Service";
import Project from "./pages/Project";
import NotFound from "./pages/NotFound";
import Forgot from "./pages/Forgot";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mm" element={<HomeMm />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="service/:id/:lang" element={<Service />} />
        <Route path="project/:id/:lang" element={<Project />} />
        <Route path="tenders" element={<Tenders />} />
        <Route path="auth" element={<Auth />}>
          <Route path="tender/:id" element={<Tender />} />
          <Route path="submit/:id" element={<Submit />} />
          <Route path="submit/:id/success" element={<Success />} />
          <Route path="submitted" element={<Submitted />} />
        </Route>
        <Route path="forgot" element={<Forgot />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
